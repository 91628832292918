
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  name: 'DelaySurveyModal',
  components: { TmFormLine, TmButton, TmModal },
  setup() {
    const valueOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]
    const timeUnitOptions = ['Seconds', 'Minutes', 'Hours', 'Days']
    const value = ref(valueOptions[0])
    const timeUnit = ref(timeUnitOptions[0])

    return {
      valueOptions,
      timeUnitOptions,
      value,
      timeUnit,
    }
  },
})
